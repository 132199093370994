@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@300&display=swap');

html, body, #root {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;

}

.tooltip{
    background-color:  rgb(30, 55, 100) !important;
    border-radius: 10% !important;
}

.highlightCell {
    background-color: rgb(0, 107, 179) !important;
    color: white;
    border-radius: 10%;
    opacity: 0.8;
}

.regularCell {
    color: black;
    border-radius: 10%;
}
.selected {
    background-color: red !important;
}
.react-calendar__tile--active{
    background-color: rgb(36, 179, 0) !important;
    opacity: 1;
   
}



.header {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    height: 100%;
    overflow: hidden

}

.underline--magical {
    background-image: linear-gradient(120deg, #00528c 0%, #00528c 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.1em;
    background-position: bottom;
    transition: background-size 0.25s ease-in;

}
